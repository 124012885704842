


































































































































































import { Notify,Toast,Dialog } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import PutAddress from "@/components/ShopComponent/BuyList/BuyAddress.vue"
import SelCoupon from "@/components/ShopComponent/BuyList/ShopSelCoupon.vue"
import { UserStore } from "@/store/modules/User"
import { IndexStore } from "@/store/modules/Index"
import { CouponStore } from "@/store/modules/Basics/Coupon"
import { ShopSucStore } from "@/store/modules/Shop/ShopSuc"
import { PayUpDataType } from "@/Api/Shop/interfaceType"
import { ShopItemType,AddressType,ShopMyCouponType } from "@/Type/index"
import { CreateOrder,PayBefore,PayAfter,GetShopCouponList } from "@/Api/Shop/index"
import Stroage from "@/util/Storage"
import { Vue,Component, Watch } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";
import {setPullData} from "@/util/compatibility_App";

// let WeixinJSBridge:any = ( "WeixinJSBridge" in window  ) ? window.WeixinJSBridge : {}; // {}
declare let WeixinJSBridge: any;
declare const document:any;

interface OrderType<T=string>{
    productId:T|number;
    number:T|number;
}

interface BuyListType<T=string>{
    loadingShow:boolean
    userId:T|number;
    token:T;
    RightIcon:T;
    DownIcon:T;
    DefaultIcon:T;
    BuyList:Array<{ title:T;content:T; }>
    ShopData:ShopItemType
    PayList:Array<{ title:T;content:T; }>
    ShopState:number;
    Message:T;
    id:T|number;
    List:Array<any>;
    appoint:boolean;
    appointTxt:T;
    Money:number;
    Price:number
    PackFee:number; // 包装费
    FoodFee:number; // 餐盒费
    ShopCoupon:any; //  是否店铺优惠卷
    CouponBool:boolean;
    CouponTxt:T;
    CommentMoney:number; // 商品金额
    UpData:PayUpDataType;
    OrderId:T;
    OrderData:any
    CouponList:ShopMyCouponType[]
    CouponData:ShopMyCouponType

    Show:boolean;

    handleChangeShow():void;
    handleGetShow(data:boolean):void;
    handleGetUse(data:ShopMyCouponType|any):void;
    initData():void;
    handleGetTypeIndex(data:any):void;
    handleFitlerAddress(data:AddressType):void;
    handlePullAppoint(data?:any):void;
    handleComputed():void;
    handleClickPay():void;
    handleGetCouponList():void;
    handleFilterCoupon():void;
    handleUpData():void;
    handlePay():void;
    onBridgeReady():void;
    handleCallBack():void;
    handleError(data:string):void;
}

@Component({ name:"BuyList",components:{ HeadTop,PutAddress,SelCoupon } })
export default class BuyList extends ZoomPage implements BuyListType{
    loadingShow = false
    userId:string|number = ""
    token = ""
    RightIcon = require("$icon/Shop/Right.png");
    DownIcon = require("$icon/Shop/Down.png")
    DefaultIcon = require("$icon/Shop/TestShop.png");
    BuyList = [
        { title:"配送信息", content:"商家配送" },
        { title:"付款方式", content:"在线支付" },
    ]
    PayList = [
        { title:"配送费", content:"￥ 0" },
        { title:"餐盒费", content:"￥ 0" },
        { title:"打包费", content:"￥ 0" },
        { title:"总计", content:"￥ 0" },
    ]
    ShopData:ShopItemType = {}
    ShopState = 0
    Message = ""
    id: string|number = ""
    List: any[] = []
    appoint = false
    appointTxt = "立即送达"; // 预约信息
    Money = 0
    Price = 0
    PackFee = 0;
    FoodFee = 0
    CommentMoney = 0;
    UpData:PayUpDataType = {
       addressId:"",
       userCouponId:"",
       disAmount:"",
       amount:"",
       fee:"",
       lunchboxFee:"",
       packFee:"",
       ptotal:"",
       remark:"",
       receiveDate:"",
       discountType:"",
       deliveryType:"",
       orderDetailList:[]
    }
    OrderId = ""
    OrderData:any = {}
    CouponList:ShopMyCouponType[] = []
    CouponData:ShopMyCouponType = {}
    ShopCoupon:any = {}
    CouponBool = false // 是否可以使用优惠卷
    CouponTxt = "请选择优惠卷"

    Show = false

    activated(){
       this.initData()
    }

    initData(){
        this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
        this.token = Stroage.GetData_ && Stroage.GetData_("token")
        let { data,id,ShopData,appoint } = this.$route.query
        this.List = JSON.parse( data as string )
        this.id = id as string
        this.ShopData = JSON.parse( ShopData as string )
        this.appoint = /true/.test( appoint as string ) || false;

        this.handleComputed()

        if( JSON.stringify( UserStore.GetSelAddress ) != "{}" ){
            this.handleFitlerAddress( UserStore.GetSelAddress )
        }

        if( ShopSucStore.GetPutCouponList.length ){
            this.CouponList = ShopSucStore.GetPutCouponList
            if( JSON.stringify( ShopSucStore.GetPutOrder.CouponData ) != "{}" ){
               this.CouponData = ShopSucStore.GetPutOrder.CouponData as ShopMyCouponType
            }
        }else{
            this.handleGetCouponList()
        }

       //  this.ShopState = ShopSucStore.GetPutOrder.StateIndex
        this.Message = ShopSucStore.GetPutOrder.Message
        this.UpData.fee = this.ShopData.deliverPrice as string
        // 订单列表
        this.UpData.orderDetailList = this.handleFilterOrder() as Array<{ productId:number;number:number; }>

    }

    handleGetTypeIndex(data:number){
        if( data ){
            this.CouponData = {}
            this.UpData.deliveryType = 2
            this.UpData.fee =  0
        }else{
            this.UpData.deliveryType = this.ShopData.deliveryType as string
            this.UpData.fee = Number( this.ShopData.deliverPrice ) || 0
        }
        this.ShopState = data
        this.handleComputed()
        this.handleFilterCoupon()
    }

    handleFitlerAddress(data:AddressType){
        this.UpData.addressId = data.id as string
    }

    handlePullAppoint(data?:any){
        this.UpData.receiveDate = data.data.title
        this.appointTxt = data.data.title as string
    }

    handleToPathCoupon(){
        let type = 0;
        switch( Number( IndexStore.GetShopState.state ) ){
            case 1:
                type = 4
                break;
            case 2:
                type = 2
                break;
            case 3:
                type = 3
                break;
        }
        this.$router.push({
            path:"/selcoupon",
            query:{
                type:type+",0"
            }
        })
    }

    handleChangeShow(){
        this.Show = !this.Show
    }

    handleGetShow(data:boolean){
        this.Show = data
    }

    handleGetUse(data:ShopMyCouponType|any){
        this.CouponData = data
        this.handleComputed()
    }

    handleComputed(){
        let Money = 0;
        let Price = 0;
        let Num = 0;
        this.List.forEach( (item,index)=>{
            Money += (item.nowPrice * item.Num)
            Price += (item.price * item.Num)
            if( item.isNeedPack == 1 ){
                Num += Number(item.Num);
            }
        } )
        this.CommentMoney = Money

        // 包装费 包装盒
        if( Num && this.ShopData.lunchboxFee && this.ShopData.packFee ){
            this.FoodFee = Num * Number( this.ShopData.lunchboxFee )
            this.PackFee = Number( this.ShopData.packFee )
            Money += ( this.FoodFee + this.PackFee )
            Price += ( this.FoodFee + this.PackFee )
            this.UpData.lunchboxFee = this.FoodFee
            this.UpData.packFee = this.ShopData.packFee
        }else{
            this.UpData.lunchboxFee = ''
            this.UpData.packFee = ''
        }

        if( this.ShopState == 0 ){
           Money += ( Number( this.ShopData.deliverPrice ) || 0 )
           Price += ( Number( this.ShopData.deliverPrice ) || 0 )
        }

        this.Money = Money
        this.Price = Price
        this.UpData.ptotal = Money
        // 店铺优惠
        let List = this.ShopData.reducteList || []
        let CouponList:Array<any> = []
        if( List!.length ){
            List?.forEach( (item,index)=>{
                if( item.status == 0 && item.fullPrice ){
                    if( this.Money >= Number( item.fullPrice ) ){
                        CouponList.push( item )
                    }
                }
            } )
        }
        if( CouponList.length ){
            CouponList = CouponList.sort( (a,b)=> Number(b.fullPrice) - Number( a.fullPrice )  )
            Money -= ( Number( CouponList[0].reducePrice ) || 0 )
            this.ShopCoupon = CouponList[0]
            this.UpData.discountType = 1
            this.UpData.disAmount = CouponList[0].reducePrice
        }else{
            this.UpData.discountType = ""
            this.UpData.disAmount = ""
            this.ShopCoupon = {}
        }

        //优惠卷
        if( JSON.stringify( this.CouponData ) != "{}" ){
            if( Number( this.CouponData.minAmount ) <= Money ){
                Money = Money - Number( this.CouponData.amount )
               //  this.CouponTxt = `大于${ this.GetCoupon.minAmount }元减${ this.GetCoupon.amount }元`
                this.CouponBool = true
                this.UpData.userCouponId = this.CouponData.id as string
            }else{
                this.CouponBool = false
                this.UpData.userCouponId = ''
               //  this.CouponTxt = `最小使用的金额为${ this.GetCoupon.minAmount } 元`
                this.handleError( `暂无法使用 最小使用的金额为 ${ this.CouponData.minAmount } 元` )
            }
        }else{
            this.CouponBool = false
            this.UpData.userCouponId = ''
        }

        if( Money < 0 )Money = 0;
        this.Money = Money
        this.Price = Price
        this.UpData.amount = Money
        this.PayList[0].content = "￥ " + (this.ShopData.deliverPrice || 0)
        this.PayList[1].content = "￥ " + this.FoodFee
        this.PayList[2].content = "￥ " + this.PackFee
        this.PayList[3].content = "￥ " + this.Money
       //  this.PayList[4].content = "￥ " + ( Price - Money )
        this.BuyList[0].content = (this.ShopData.deliveryType == 1) ? '商家配送' : '平台配送'
    }

    handleFilterOrder():Array<OrderType>{
        let List:Array<OrderType> = []
        this.List.forEach( (item,index)=>{
            List.push({
                productId:item.id as number,
                number:item.Num as number
            })
        } )
        return List
    }

    handleGetCouponList(){
        GetShopCouponList({
            userId:this.userId,
            token:this.token
        },{
            storeId:this.id,
            status:0
        }).then( res=>{
            if( res.message.code === "200" ){
                this.CouponList = res.data
                this.handleFilterCoupon()
                ShopSucStore.SetPutCouponList( res.data )
            }else{
                this.handleError( res.message.msg )
            }
        } )
    }

    handleFilterCoupon(){
       if ( this.CouponList.length < 1 )return;
       let List:any[] = []
       if( this.CouponList!.length ){
           this.CouponList?.forEach( (item,index)=>{
               if( item.status == 0 && item.minAmount ){
                   if( this.Money >= Number( item.minAmount ) ){
                       List.push( item )
                   }
               }
           } )
       }
       if( List.length ){
           List = List.sort( (a,b)=> Number( b.amount ) - Number( a.amount ) )
           this.CouponData = List[0]
       }
       this.handleComputed()
    }

    handleClickPay(){
        let { UpData } = this
        if( !String( UpData.addressId ).length ){
            this.handleError("请选择地址")
            console.log( UpData )
            return
        }
        // 留言
        this.Message = this.Message.replace(/ /g,"")
        console.log( this.UpData )
        if( !this.Message.length ){
           Dialog.confirm({
               title: '温馨提醒',
               message: '亲！提交订单需要支付金额;\n您不考虑输入留言吗？',
               confirmButtonText:"不考虑",
               cancelButtonText:"考虑一下",
               className:"HideClassName",
               overlayClass:"MaskHideClassName"
           }).then(res=>{
               this.handleUpData()
           }).catch( cat=>cat )
        }else{
            this.UpData.remark = this.Message
            this.handleUpData()
        }
    }

    handleUpData(){
        this.loadingShow = true
        new Promise( (reslove,reject)=>{
           CreateOrder({
               userId:this.userId,
               token:this.token
           },this.UpData).then( res=>{
               console.log( res )
               if( res.message.code === "200" ){
                   this.OrderId = res.data
                   if( this.Money < 0){
                       Toast({
                           message:"支付成功",
                           icon:"none",
                           className:"ToastClassName"
                       })
                       let time = setTimeout( ()=>{
                           this.$router.go(-1)
                           clearTimeout(time)
                       },1000 )
                       reject(true)
                   }else{
                       reslove( res.data )
                   }
               }else{
                   reject()
               }
           } )
        } ).then(res=>{
            let bool = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
            if ( !(bool != null && bool[0] == "micromessenger") ){
                this.loadingShow = false
                this.$router.push({
                    path:"/goPay",
                    query:{
                        orderId:this.OrderId,
                        state:"shop",
                        money:this.Money + '',
                        price:(this.Price - this.Money).toFixed(2)
                    }
                })
                // setPullData({ orderId:this.OrderId })
            }else{
                PayBefore({
                    userId:this.userId,
                    token:this.token
                },{
                    orderId:res as string
                }).then( res=>{
                    console.log( res )
                    if( res.message.code === "200" ){
                        this.OrderData = res.data
                        this.handlePay()
                    }else{
                        this.handleError( res.message.msg )
                    }
                } )

            }
            ShopSucStore.SetPutCouponList([])
            let data = ShopSucStore.GetPutOrder
            data.TimeIndex = 0
            data.AskIndex = 0
            data.StateIndex = 0
            data.CouponData = {}
            data.Message = ''
            ShopSucStore.SetPutOrder(data)
        }).catch(cat=>{
            this.loadingShow = false
            if( !cat ){
                this.handleError( cat.message.msg )
            }
        })
    }

    handlePay(){
       if ( "WeixinJSBridge" in window  && typeof WeixinJSBridge == "undefined"){
           if( document.addEventListener ){
               document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
           }else if ( "attachEvent" in document ){
               document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
               document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
           }
       }else{
           this.onBridgeReady();
       }
   }

    onBridgeReady(){
       ( "invoke" in WeixinJSBridge) && WeixinJSBridge.invoke(
           'getBrandWCPayRequest', {
               "appId":this.OrderData.appId,//公众号名称，由商户传入
               "timeStamp":this.OrderData.timeStamp,//时间戳，自1970年以来的秒数
               "nonceStr":this.OrderData.nonceStr, //随机串
               "package":this.OrderData.package,
               "signType":this.OrderData.signType,//微信签名方式：
               "paySign":this.OrderData.paySign //微信签名
           },
           (res:any)=>{
               if(res.err_msg == "get_brand_wcpay_request:ok" ){
                   this.handleCallBack()
               }
           });
    }

    handleCallBack(){
        PayAfter({
           userId:this.userId,
           token:this.token
        },{
            orderId:this.OrderId
        }).then( res=>{
            console.log( res )
            this.loadingShow = false
            if( res.message.code === "200" ){
               Toast({
                   message:"支付成功",
                   icon:"none",
                   className:"ToastClassName"
               })
               let time = setTimeout( ()=>{
                   this.$router.go(-1)
                   clearTimeout(time)
               },1000 )
            }else{
                this.handleError( res.message.msg )
            }
        } )
    }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }

   get GetCoupon(){
       return CouponStore.GetSelCoupon
   }

   @Watch("ShopState")
   handleStateIndex(newVal:number){
       let data = ShopSucStore.GetPutOrder
       data.StateIndex = newVal
       ShopSucStore.SetPutOrder( data )
   }

   @Watch("Message")
   handleMessage(newVal:string){
       let data = ShopSucStore.GetPutOrder
       data.Message = newVal
       ShopSucStore.SetPutOrder( data )
   }
}
