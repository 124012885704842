



















































import ScrollView from "@/components/ShareComponent/PullDownUp.vue"
import { ShopMyCouponType } from "@/Type/index"
import { ShopSucStore } from "@/store/modules/Shop/ShopSuc"
import { Vue,Component,Prop,Watch,Emit } from "vue-property-decorator"

interface ShopSelCouponType<T=string>{
    cardBack:T;
    Show:boolean;

    handleChangeShow():void;
    handleUse(data:ShopMyCouponType):void;
    handleFitler(str:string):void;
}

@Component({ name:"ShopSelCoupon",components:{ ScrollView } })
export default class ShopSelCoupon extends Vue implements ShopSelCouponType{
    cardBack = require("$icon/Shop/CardBack.png")
    Show = false

    mounted() {
       let data = ShopSucStore.GetPutOrder
       if( JSON.stringify( data.CouponData ) != "{}" ){
           // this.PullSelCoupon( data.CouponData as ShopMyCouponType )
       }
    }

    handleChangeShow(){
        this.PullShow( !this.Show )
    }

    handleUse(data:ShopMyCouponType){
        this.PullSelCoupon( data )
        this.handleChangeShow()
        let BuyData = ShopSucStore.GetPutOrder
        BuyData.CouponData = data
        ShopSucStore.SetPutOrder( BuyData )
    }

    handleFitler(str:string){
        if( str && str.length ){
            return str.split(" ")[0]
        }else{
            return str
        }
    }

    @Emit("PullShow")
    PullShow(data:boolean){
        return data
    }

    @Emit("PullSelCoupon")
    PullSelCoupon(data:ShopMyCouponType){
        return data
    }

    @Prop(Boolean)
    show!:boolean

    get GetShow(){
       return this.show
    }

    @Watch("GetShow")
    ChangeShow(newVal:boolean){
        if( newVal ){
            document.body.style.overflow = "hidden"
        }else{
            document.body.style.overflow = "auto"
        }
        this.Show = newVal
    }

    @Prop(Array)
    data!:ShopMyCouponType[]

    get GetData(){
        return this.data
    }
}
