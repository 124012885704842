







































































































import { Notify,Dialog } from "vant"
import PulSelItem from "@/components/ShareComponent/PullSelItem.vue"
import { UserStore } from "@/store/modules/User"
import { ShopSucStore } from "@/store/modules/Shop/ShopSuc"
import { AddressType,ShopItemType } from "@/Type/index"
import { FilterAppointList } from "@/util/index"
import { GetDefaultAddress } from "@/Api/Basics/index"
import Stroage from "@/util/Storage"
import { Vue,Component, Emit, Watch } from "vue-property-decorator"

interface BuyAddressType<T=string>{
    loadingShow:boolean;
    userId:T|number;
    token:T;
    RightIcon:T;
    SiteIcon:T;
    PutIcon:T;
    AskIcon:T;
    PenIcon:T;
    SelList:string[];
    SelIndex:number;
    ShopData:ShopItemType
    AddressBool:boolean
    AddressData:AddressType

    AppointList:Array<{ title:T;value:number }>
    AppointIndex:number;
    AppointTit:T;
    AskTit:T;
    AskIndex:number;
    AskList:Array<{ title:T;value:number }>
    Appoint:boolean;

    initData():void;
    handleChangeState(state:number):void;
    handlePullAppoint(data:any):void;
    handlePullAsk(data:any):void;
    handleGetAddress(): void
    handleError(data:string):void;
}

@Component({ name:"BuyAddress",components:{ PulSelItem } })
export default class BuyAddress extends Vue implements BuyAddressType{
   loadingShow = false
   userId:string|number = ""
   token = ""
   RightIcon = require("$icon/Basics/Right.png");
   SiteIcon = require("$icon/Basics/location.png");
   PutIcon = require("$icon/Shop/Put.png");
   AskIcon = require("$icon/Shop/oneself.png")
   PenIcon = require("$icon/Basics/Write.png")
   SelList:string[] = [ '外卖配送','到店自取' ]
   SelIndex = 0
   AddressBool = false
   ShopData:ShopItemType = {}
   AddressData:AddressType = {}
   AppointList:Array<{ title:string;value:number }> = []
   AppointIndex = 0
   AppointTit = "立即送达"
   AskTit = "立即去取"
   AskIndex = 0
   AskList:Array<{ title:string;value:number }> = []
   Appoint = false

   mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")   
       this.initData()
   }

   initData(){
       let { ShopData,appoint } = this.$route.query
       
       let data = ShopSucStore.GetPutOrder
       if( data.TimeList.length ){
           this.AppointList = data.TimeList
           this.AppointIndex = data.TimeIndex
           this.AppointTit = data.TimeList[ data.TimeIndex ]!.title as string
       }else{
           this.AppointList = [ { title:"立即送达",value:-1 },...FilterAppointList() ]
       }

       if( data.AskList.length ){
           this.AskList = data.AskList
           this.AskIndex = data.AskIndex
           this.AskTit = data.AskList[ data.AskIndex ]!.title as string
       }else{
           this.AskList = [ { title:"立即去取",value:-1 },...FilterAppointList() ]
       }

       this.Appoint = /true/.test( appoint as string ) || false;
       this.SelIndex = data.StateIndex
       this.ShopData = JSON.parse( ShopData as string )
       
       if( this.SelIndex ){
           this.pullActive( { data:this.AskList[ this.AskIndex ] } )
       }else{
           this.pullActive( { data:this.AppointList[ this.AppointIndex ] } )
       }

       if( JSON.stringify( UserStore.GetSelAddress ) != "{}" ){
           this.AddressData = UserStore.GetSelAddress
           this.AddressBool = true
       }else{
           this.AddressBool = false
           let time = setTimeout(()=>{
               this.handleGetAddress()
               clearTimeout( time )
           })
       }
   }

   handleChangeState(state:number){
       if( this.SelIndex != state ){
           this.SelIndex = state
       }
   }

   handlePullAppoint(data:any){
       this.AppointTit = data.data.title
       this.AppointIndex = data.index
       this.pullActive( data )
   }

   handlePullAsk(data:any){
       this.AskTit = data.data.title
       this.AskIndex = data.index
       this.pullActive( data )
   }

   handleSelAddress(){
       this.$router.push({
           path:"/addressList",
           query:{
               select:"true"
           }
       })
   }

   handleGetAddress(): void {
       this.loadingShow = true
       GetDefaultAddress({
           userId:this.userId,
           token:this.token
       }).then( (res:any)=>{
           this.loadingShow = false
           if ( res.message.code === "200" ) {
               if ( res.data ) {
                   this.AddressData = res.data
                   this.AddressBool = true
                   UserStore.SetSelAddress( res.data )
                   this.PullAddress( res.data )
               }else{
                   this.AddressBool = false
                   Dialog.confirm({
                       title: '温馨提醒',
                       message: '请添加或选择地址',
                       className:"HideClassName",
                       overlayClass:"MaskHideClassName"
                   }).then(()=>{
                       this.$router.push({
                           path:"/addressList",
                           query:{
                               select:"true"
                           }
                       })
                   }).catch(()=>{
                       //
                   })
               }
           }else{
               this.handleError( res.message.msg )
           }
       } )
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });            
   }

   @Watch("SelIndex")
   ChangeSelIndex(newVal:number){
       let data = ShopSucStore.GetPutOrder
       data.StateIndex = newVal
       ShopSucStore.SetPutOrder( data )
       this.pullSelIndex( newVal )
       this.initData()
   }

   @Emit("pullSelIndex")
   pullSelIndex(data:number){
       return data
   }

   @Emit("pullActive")
   pullActive(data:any){
       return data
   }

   @Emit("PullAddress")
   PullAddress(data:AddressType){
       return data
   }

   @Watch("AppointList")
   handleAppointList(newVal:Array<{ title:string;value:number }>){
       let data = ShopSucStore.GetPutOrder
       data.TimeList = newVal
       ShopSucStore.SetPutOrder( data )
   }

   @Watch("AppointIndex")
   handleAppointIndex(newVal:number){
       let data = ShopSucStore.GetPutOrder
       data.TimeIndex = newVal
       ShopSucStore.SetPutOrder( data )
   }

   @Watch("AskList")
   handleAskList(newVal:Array<{ title:string;value:number }>){
       let data = ShopSucStore.GetPutOrder
       data.AskList = newVal
       ShopSucStore.SetPutOrder( data )
   }

   @Watch("AskIndex")
   handleAskIndex(newVal:number){
       let data = ShopSucStore.GetPutOrder
       data.AskIndex = newVal
       ShopSucStore.SetPutOrder( data )
   }
}
